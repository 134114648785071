import React, { useState } from "react";
import img1 from "../assets/oslite/smOsliteBanner.png";
import img2 from "../assets/TeachTech/Asset 8@3x.png";
import Footer from "../Components/Footer";

export const data = [
  {
    id: 0,
    date: "April 26th, 2024 (Friday)",
    place: "Bihar",
    time: "12:30 pm to 01.30 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Mr. Kumar Vimlendu Singh",
    contact: [
      "Amit Kumar +91 7667603909, amitk@eupheus.in",
      "Tapan Kumar +91 7667603909, amitk@eupheus.in",
      "Dheeraj Kumar +91 7667603909, amitk@eupheus.in",
      "Danish Iqbal +91 7667603909, amitk@eupheus.in",
      "Sagar Sinha +91 7667603909, amitk@eupheus.in",
    ],
  },
  {
    id: 1,
    date: "April 26th, 2024 (Friday)",
    place: "Jharkhand",
    time: "11:30 pm to 12.30 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Mr. Ujjawal Kumar",
    contact: [
      "Irshad Haider +91 9334197061, irshadh@eupheus.in",
      "Pankaj Kumar +91 9334197061, irshadh@eupheus.in",
      "Ankit Dudeja +91 9334197061, irshadh@eupheus.in",
      "Debasish Bid +91 9334197061, irshadh@eupheus.in",
      "Nishant Bhaskar +91 9334197061, irshadh@eupheus.in",
    ],
  },
  {
    id: 2,
    date: "April 26th, 2024 (Friday)",
    place: "Haryana",
    time: "05:00 pm to 06.00 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Ms. Anjali Singh",
    contact: [
      "Abhay Dubey +91 9910698385, abhayd@eupheus.in",
      "Bhupender Singh +91 9996609434, bhupenders@eupheus.in",
    ],
  },
  {
    id: 3,
    date: "April 30th, 2024 (Tuesday)",
    place: "Delhi (NCR)",
    time: "05:30 pm to 06.30 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Ms. Beant Lamba",
    contact: [
      "Naresh Vashisth +91 98910 17188, nareshv@eupheus.in",
      "Chhitiz Sisodia +91 93111 05718, chhitizs@eupheus.in",
      "Sumit Goyal +91 98999 99241, sumitg@eupheus.in",
      "Sumit Kumar +91 98730 05059, sumitk@eupheus.in",
    ],
  },
  {
    id: 4,
    date: "May 11th, 2024 (Saturday)",
    place: "Bihar",
    time: "11:00 am to 12.00 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Ms. Beant Lamba",
    contact: [
      "Amit Kumar +91 7667603909, amitk@eupheus.in",
      "Tapan Kumar +91 7667603909, amitk@eupheus.in",
      "Dheeraj Kumar +91 7667603909, amitk@eupheus.in",
      "Danish Iqbal +91 7667603909, amitk@eupheus.in",
      "Sagar Sinha +91 7667603909, amitk@eupheus.in",
    ],
  },
  {
    id: 5,
    date: "May 11th, 2024 (Saturday)",
    place: "Jharkhand",
    time: "11:00 am to 12.00 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Ms. Beant Lamba",
    contact: [
      "Irshad Haider +91 9334197061, irshadh@eupheus.in",
      "Pankaj Kumar +91 9334197061, irshadh@eupheus.in",
      "Ankit Dudeja +91 9334197061, irshadh@eupheus.in",
      "Debasish Bid +91 9334197061, irshadh@eupheus.in",
      "Nishant Bhaskar +91 9334197061, irshadh@eupheus.in",
    ],
  },
  {
    id: 5,
    date: "May 11th, 2024 (Saturday)",
    place: "U.P.",
    time: "03:00 pm to 04.00 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Mr. Abid Akhtar Ansari",
    contact: [
      "Ajay Tiwari +91 9334197061, irshadh@eupheus.in",
      "Sarvesh Kumar +91 9334197061, irshadh@eupheus.in",
      "Deepak Mishra +91 9334197061, irshadh@eupheus.in",
      "Mayank Raj +91 9334197061, irshadh@eupheus.in",
      "Trideep Gautam +91 9334197061, irshadh@eupheus.in",
      "Ankit Kumar Singh +91 9334197061, irshadh@eupheus.in",
      "Prashant Singh +91 9334197061, irshadh@eupheus.in",
      "Rakesh Kumar +91 9334197061, irshadh@eupheus.in",
      "Praveen Singh Singh +91 9334197061, irshadh@eupheus.in",
      "Dilshad Ali Singh +91 9334197061, irshadh@eupheus.in",
      "Syed Azeem Ali +91 9334197061, irshadh@eupheus.in",
      "Afshan Ayaaz +91 9334197061, irshadh@eupheus.in",
      "Danish Khan +91 9334197061, irshadh@eupheus.in",
      "Altamish Makki +91 9334197061, irshadh@eupheus.in",
      "Puspendra Kumar +91 9334197061, irshadh@eupheus.in",
      "Peeyush Ojha +91 9334197061, irshadh@eupheus.in",
      "Vikul Kumar +91 9334197061, irshadh@eupheus.in",
      "Vijay Gupta +91 9334197061, irshadh@eupheus.in",
    ],
  },
  {
    id: 5,
    date: "May 11th, 2024 (Saturday)",
    place: "U.P.",
    time: "01:30 pm to 02.30 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Mr. Abid Akhtar Ansari",
    contact: [
      "Ajay Tiwari +91 9334197061, irshadh@eupheus.in",
      "Sarvesh Kumar +91 9334197061, irshadh@eupheus.in",
      "Deepak Mishra +91 9334197061, irshadh@eupheus.in",
      "Mayank Raj +91 9334197061, irshadh@eupheus.in",
      "Trideep Gautam +91 9334197061, irshadh@eupheus.in",
      "Ankit Kumar Singh +91 9334197061, irshadh@eupheus.in",
      "Prashant Singh +91 9334197061, irshadh@eupheus.in",
      "Rakesh Kumar +91 9334197061, irshadh@eupheus.in",
      "Praveen Singh Singh +91 9334197061, irshadh@eupheus.in",
      "Dilshad Ali Singh +91 9334197061, irshadh@eupheus.in",
      "Syed Azeem Ali +91 9334197061, irshadh@eupheus.in",
      "Afshan Ayaaz +91 9334197061, irshadh@eupheus.in",
      "Danish Khan +91 9334197061, irshadh@eupheus.in",
      "Altamish Makki +91 9334197061, irshadh@eupheus.in",
      "Puspendra Kumar +91 9334197061, irshadh@eupheus.in",
      "Peeyush Ojha +91 9334197061, irshadh@eupheus.in",
      "Vikul Kumar +91 9334197061, irshadh@eupheus.in",
      "Vijay Gupta +91 9334197061, irshadh@eupheus.in",
    ],
  },
  {
    id: 5,
    date: "May 11th, 2024 (Saturday)",
    place: "Uttarakhand",
    time: "03:00 pm to 04.00 pm",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Mr. Abid Akhtar Ansari",
    contact: [
      "Tribhuwan Pal +91 9334197061, irshadh@eupheus.in",
      "Kunal Verma +91 9334197061, irshadh@eupheus.in",
      "Prashant Mishra +91 9334197061, irshadh@eupheus.in",
      "Vinod Awasthi +91 9334197061, irshadh@eupheus.in",
      "Puspendra Kumar +91 9334197061, irshadh@eupheus.in",
      "Peeyush Ojha +91 9334197061, irshadh@eupheus.in",
      "Vikul Kumar +91 9334197061, irshadh@eupheus.in",
    ],
  },

  {
    id: 7,
    date: "May 11th, 2024 (Saturday)",
    place: "Karnataka",
    time: "03:00 pm to 04.00 pm ",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Ms. Farheen Fathima",
    contact: [
      "Suresha A.R +91 9740728069, arsuresha@eupheus.in",
      "Gautam Rathore +91 7455945555, gautamr@eupheus.in",
    ],
  },
  {
    id: 8,
    date: "May 18th, 2024 (Saturday)",
    place: "Karnataka",
    time: "03:00 pm to 04.00 pm ",
    link: "https://shorturl.at/xFNW6",
    hostedBy: "Ms. Farheen Fathima",
    contact: [
      "Suresha A.R +91 9740728069, arsuresha@eupheus.in",
      "Gautam Rathore +91 7455945555, gautamr@eupheus.in",
    ],
  },
];

function SmOslite() {
  const [active, setActive] = useState(0);

  return (
    <div
      className={`w-full flex flex-col bg-white transition-all duration-200 ease-linear overflow-hidden`}
    >
      <div>
        <img src={img1} alt="" className="w-full" />
        <div>
          <p className="p-8 bg-[(245,245,245)] text-center font-medium text-base sm:text-2xl">
            Welcome to our webinar on 21st Century School OS LITE, an initiative
            of Eupheus Learning. OS LITE is our school digitalization tool and
            it has many benefits for Principals, Teachers, Parents and Students.
            This webinar will address the features and benefits of this digital
            tool and handle queries on how to activate and starting using it in
            simple steps. By onboarding on OS LITE, your school will
            successfully implement a key mandate of NEP 2020.
          </p>
          <div className="bg-gray-600">
            <span className="flex flex-col justify-center items-center py-4 font-bold lg:text-4xl md:text-3xl sm:text-xl text-lg text-white">
              Upcoming Events
            </span>
            <div className="flex flex-row items-center gap-3 sm:w-[100%] p-3 overflow-auto">
              {data.map((item, index) => (
                <div className="flex flex-col justify-center items-center gap-2 cursor-pointer ">
                  <div
                    onClick={() => setActive(index)}
                    className={`flex flex-col justify-center items-center box-content border transition-all duration-150 ease-linear hover:bg-[#F2AC1D] rounded-lg ${
                      index === active ? "bg-[#F2AC1D]" : "bg-gray-100"
                    } p-6 m-4 w-36 text-lg`}
                  >
                    <img src={img2} alt="" className="w-20 h-20" />
                    <p>{item.date.split(",")[0]} 2024</p>
                    <span className="text-sm">({item.place})</span>
                  </div>
                  <a
                    href="https://www.eupheus.in/osliteregistration_form"
                    target="_blank"
                  >
                    <button
                      className={`flex flex-col justify-center transition-all duration-150 ease-linear hover:bg-[#F2AC1D] items-center ${
                        index === active ? "bg-[#F2AC1D]" : "bg-gray-100"
                      } border rounded-full px-3`}
                    >
                      Book Your Slot
                    </button>
                  </a>
                </div>
              ))}
            </div>

            <div className="flex flex-col justify-center items-center bg-gray-300 p-5">
              <h2 className="lg:text-4xl md:text-3xl sm:text-xl text-lg font-light">
                We welcome you to the session on
              </h2>
              <h1 className="lg:text-4xl md:text-3xl sm:text-xl text-lg font-bold text-red-600 py-3">
                OS LITE onboarding
              </h1>
              <div className="flex text-sm md:text-base flex-col justify-center sm:w-[60%] px-2">
                <div className="w-full flex justify-center">
                  <div className="w-fit flex flex-col">
                    <span className="font-semibold">Topics to be covered:</span>
                    <ul className="p-2">
                      <li className="list-disc py-2">What is OS LITE?</li>
                      <li className="list-disc py-2">
                        Features & Benefits of OS LITE
                      </li>
                      <li className="list-disc py-2">
                        How to activate OS LITE for your school
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="h-px flex-grow bg-yellow-500 border-3 border-yellow-500" />
                <ul className="flex flex-col justify-center items-center p-2">
                  <li className=" py-2">
                    <span className="font-semibold">Date: </span>
                    {data[active].date}
                  </li>
                  <li className="py-2">
                    <span className="font-semibold">Time: </span>
                    {data[active].time}
                  </li>
                  <li className="py-2">
                    <span className="font-semibold">Session hosted by: </span>
                    {data[active].hostedBy}
                  </li>
                  <li className=" py-2">
                    <span className="font-semibold">Register at: </span>
                    <a
                      href="https://www.eupheus.in/osliteregistration_form"
                      target="_blank"
                    >
                      eupheus.in/osliteregistration_form
                    </a>
                  </li>
                </ul>
                <hr className="h-px flex-grow bg-yellow-500 border-3 border-yellow-500" />
                <span className="font-semibold flex flex-col justify-center items-center p-2">
                  Contact Person for RSVP:
                </span>
                <ul className="flex flex-col justify-center items-center p-2">
                  {data[active].contact.map((item) => {
                    return (
                      <li className="py-2">
                        <span className="font-semibold">
                          {item.split("+91")[0]}
                        </span>
                        +91 {item.split("+91")[1]}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="flex items-center justify-center mb-3">
                <a
                  href="https://www.eupheus.in/osliteregistration_form"
                  target="_blank"
                >
                  <button className="flex flex-col justify-center items-center bg-red-600 text-white border-white drop-shadow-xl rounded-full px-3">
                    Book Your Slot
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SmOslite;
