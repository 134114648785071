import React, { useEffect, useState } from "react";
import img1 from "../assets/TeachTech/banner@3x.png";
import Footer from "../Components/Footer";

function TeachTechFeedback() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("https://forms.gle/baUMnhmQBiz5NXT46");
    }, 3000);
  }, []);
  return (
    <div
      className={`w-full flex flex-col bg-white transition-all duration-200 ease-linear overflow-hidden`}
      style={{ fontFamily: "Poppins" }}
    >
      <div className="relative">
        <img src={img1} alt="" className="w-full" />
        <a
          className="flex justify-center w-full absolute bottom-2"
          style={{ backgroundColor: "rgb(245, 245, 245)" }}
          href="https://forms.gle/baUMnhmQBiz5NXT46"
          target="_blank"
        >
          <div className="px-8 py-4 font-medium text-lg rounded-md hover:shadow-2xl bg-blue-600 text-gray-100">
            Feedback
          </div>
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default TeachTechFeedback;
