import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useLayoutEffect } from "react";
import BasicButton from "../Components/Material/Button";
import { data } from "./SmOslite";
import instance from "../Instance";
import Footer from "../Components/Footer";
import logo from "../assets/oslite/smOsliteBanner2.png";
import BackDropLoader from "../Components/Material/BackDrop";

const OsLiteRegistration = () => {
  const [disable, setDisable] = React.useState(true);
  const [filtered, setFiltered] = React.useState([]);
  const [state, setState] = React.useState([]);
  const [salesPersons, setSalesPersons] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      state: "",
      city: "",
      email: "",
      dateOfWebinar: "",
      Designation: "",
      eupheusSales: "",
      schoolName: "",
      mobile: null,
    },
    validate: (values) => {
      let errors = {};
      if (!values.firstName) {
        errors.firstName = "Required";
      }
      if (!values.lastName) {
        errors.lastName = "Required";
      }
      if (!values.state) {
        errors.state = "Required";
      }
      if (!values.city) {
        errors.city = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      }
      if (!values.email.includes("@")) {
        errors.email = "Invalid email address";
      }
      if (!values.dateOfWebinar) {
        errors.dateOfWebinar = "Required";
      }
      if (!values.Designation) {
        errors.Designation = "Required";
      }
      if (!values.eupheusSales) {
        errors.eupheusSales = "Required";
      }
      if (!values.schoolName) {
        errors.schoolName = "Required";
      }
      if (!values.mobile) {
        errors.mobile = "Required";
      }
      if (values.mobile && values.mobile.toString().length != 10) {
        errors.mobile = "Length should be 10";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const res = await instance({
        url: "oslite/registration",
        method: "POST",
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          state: values.state,
          city: values.city,
          email: values.email,
          dateOfWebinar: values.dateOfWebinar,
          Designation: values.Designation,
          eupheusSales: values.eupheusSales,
          schoolName: values.schoolName,
          mobile: values.mobile.toString(),
        },
      }).catch((err) => {
        console.log(err);
        alert("Something went wrong");
        setLoading(false);
      });
      if (res.status === 200) {
        alert("Successfully Registered");
        window.location.reload();
      }
      setLoading(false);
    },
  });
  function isDateEqualOrGreaterThanToday(dateString) {
    // Extract the date parts from the string
    const dateParts = dateString.match(
      /(\w+) (\d+)(?:st|nd|rd|th), (\d+) \((\w+)\)/
    );

    // Check if the string matches the expected format
    if (!dateParts || dateParts.length < 5) {
      throw new Error("Invalid date string format");
    }

    // Map the month name to its index (January is 0, February is 1, etc.)
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = months.indexOf(dateParts[1]);

    // Create a new Date object with the extracted date parts
    const date = new Date(
      parseInt(dateParts[3]),
      monthIndex,
      parseInt(dateParts[2])
    );

    // Get the current date
    const today = new Date();

    // Compare the dates
    return (
      date > today ||
      (date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate())
    );
  }

  const returnDates = () => {
    const dates = data.filter((item) => item.place === formik.values.state);
    setFiltered(dates);
  };

  const NcrState = (state) => {
    if (state === "U.P.") {
      return "(Excluding Ghaziabad, Noida and Gr. Noida)";
    }
    if (state === "Haryana") {
      return "(Excluding Gurgaon and Faridabad)";
    }
    if (state === "Delhi (NCR)") {
      return "(Including Gurgaon, Faridabad, Ghaziabad, Noida and Gr. Noida)";
    }
  };

  useLayoutEffect(() => {
    const getStates = async () => {
      const res = await instance({
        url: "oslite/getAllStates",
        method: "GET",
      });
      if (res.status === 200) {
        setState(res.data);
      }
    };
    getStates();
  }, []);

  const getSalesPersons = async (stateName) => {
    const currState = state.filter((item) => item.state === stateName);
    const res = await instance({
      url: "oslite/getSalesPersonByState",
      method: "POST",
      data: {
        stateId: currState[0].id,
      },
    });
    if (res.status === 200) {
      setSalesPersons(res.data);
    }
  };

  return (
    <div className="bg-white flex flex-col gap-2 max-w-[100vw] min-h-screen items-center">
      <img src={logo} className="w-full h-auto mx-8" />
      <BackDropLoader loading={loading} />
      <div className="w-full gap-8 p-8 grid sm:grid-cols-3 grid-cols-1">
        <TextField
          id="firstName"
          name="firstName"
          label="First Name"
          onChange={(e) => {
            formik.values.firstName = e.target.value;
          }}
          error={Boolean(formik.errors.firstName)}
          helperText={formik.errors.firstName}
        />
        <TextField
          id="lastName"
          name="lastName"
          label="Last Name"
          onChange={(e) => {
            formik.values.lastName = e.target.value;
          }}
          error={Boolean(formik.errors.lastName)}
          helperText={formik.errors.lastName}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          onChange={(e) => {
            formik.values.email = e.target.value;
          }}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
        />
        <TextField
          id="mobile"
          name="mobile"
          label="Phone No."
          type="number"
          onChange={(e) => {
            formik.values.mobile = Number(e.target.value);
          }}
          error={Boolean(formik.errors.mobile)}
          helperText={formik.errors.mobile}
        />
        <TextField
          id="Designation"
          name="Designation"
          label="Designation"
          onChange={(e) => {
            formik.values.Designation = e.target.value;
          }}
          error={Boolean(formik.errors.Designation)}
          helperText={formik.errors.Designation}
        />
        <TextField
          id="schoolName"
          name="schoolName"
          label="School Name"
          onChange={(e) => {
            formik.values.schoolName = e.target.value;
          }}
          error={Boolean(formik.errors.schoolName)}
          helperText={formik.errors.schoolName}
        />

        <FormControl fullWidth error={Boolean(formik.errors.state)}>
          <InputLabel id="state">States</InputLabel>
          <Select
            labelId="state"
            id="state"
            label="state"
            onChange={(e) => {
              formik.values.state = e.target.value;
              setDisable(false);
              returnDates();
              getSalesPersons(formik.values.state);
            }}
          >
            {state.map((item) => (
              <MenuItem value={item.state}>
                {item.state} {NcrState(item.state)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.errors.state}</FormHelperText>
        </FormControl>
        <TextField
          id="city"
          name="city"
          label="City"
          onChange={(e) => {
            formik.values.city = e.target.value;
          }}
          error={Boolean(formik.errors.city)}
          helperText={formik.errors.city}
        />
        <FormControl fullWidth error={Boolean(formik.errors.dateOfWebinar)}>
          <InputLabel id="demo-simple-select-label">
            Date Of Webinars
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            disabled={disable}
            onChange={(e) => {
              formik.values.dateOfWebinar = e.target.value;
            }}
            error={Boolean(formik.errors.dateOfWebinar)}
            helperText={formik.errors.dateOfWebinar}
          >
            {filtered?.map((item) =>
              isDateEqualOrGreaterThanToday(item.date) ? (
                <MenuItem value={item.date + " " + item.time}>
                  {item.date} ({item.time})
                </MenuItem>
              ) : null
            )}
          </Select>
          <FormHelperText>{formik.errors.dateOfWebinar}</FormHelperText>
        </FormControl>

        <FormControl fullWidth error={Boolean(formik.errors.eupheusSales)}>
          <InputLabel id="demo-simple-select-label">
            Eupheus Sales Person
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            disabled={disable}
            onChange={(e) => {
              formik.values.eupheusSales = e.target.value;
            }}
            error={Boolean(formik.errors.eupheusSales)}
            helperText={formik.errors.eupheusSales}
          >
            {salesPersons.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.errors.eupheusSales}</FormHelperText>
        </FormControl>
      </div>
      <div className="pl-8 mb-3" onClick={formik.handleSubmit}>
        <BasicButton text={"submit"} />
      </div>

      <Footer />
    </div>
  );
};

export default OsLiteRegistration;
